import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { reduxActionSucceeded, reduxActionFailed } from '@ecster/util';
import {Button, Link, Spinner, Logo, GeneralErrorMessagePanel} from '@ecster/components';
import { i18n } from '@ecster/i18n';
import { getCanaryValue } from '../../../common/canaryValues';
import { LoginResponsivePanel } from '../components/LoginResponsivePanel';
import { AppIsClosedMessage } from '../components/AppIsClosedMessage';
import { AppWillCloseMessage } from '../components/AppWillCloseMessage';
import LoginFooter from '../../home/LoginFooter';

import './LoginFormNETS.scss';
import { UpdatedLoginMessage } from '../components/UpdatedLoginMessage';
import GlobalNotificationsPanel from '../../../common/GlobalNotificationsPanel';

class LoginFormNETS extends Component {
    state = {
        isLoading: false,
    };

    componentDidUpdate(prevProps) {
        if (reduxActionSucceeded('createSession', prevProps, this.props)) {
            const { loginProgress: { startURL } = {}, loginStatus: { sessionKey } = {} } = this.props;

            if (sessionKey && startURL) {
                window.location.href = startURL;
            }
        }

        if (reduxActionFailed('createSession', prevProps, this.props)) {
            this.setState({ isLoading: false });
        }
    }

    startNETSLogin = () => {
        this.setState({ isLoading: true });

        const { createSession } = this.props;

        const url = new URL(window.location.href);
        url.hash = '#/nets/login';

        createSession(
            {
                type: 'BANKID_MOBILE',
                returnUrl: url.href,
            },
            getCanaryValue()
        );
    };

    render() {
        const { isLoading } = this.state;

        return (
            <div className="login-form-nets-wrapper">
                <AppIsClosedMessage />
                <div className="login-responsive-panel-ctr">
                    <AppWillCloseMessage />
                    <UpdatedLoginMessage />
                    <GlobalNotificationsPanel onlyForcePopup />
                    <LoginResponsivePanel id="login-page-panel">
                        <div className="login-form-nets-wrapper-children">
                            <div className="ecster-logo-container">
                                <Logo className="ecster-logo-desktop" withName width="190px" />
                            </div>
                            <h1 className="login-heading e-yellow">{i18n('authentication.login.se.desktop.header')}</h1>

                            <div className="login-form-nets">
                                {!isLoading && (
                                    <Button onClick={this.startNETSLogin} iconRight="icon-external-link" green round>
                                        {i18n('authentication.nets.se.login')}
                                    </Button>
                                )}

                                {isLoading && <Spinner size="large" isVisible isCenterX id="is-nets-in-progress" />}

                                <Link strong white to="/authentication/help">
                                    {i18n('general.help')}
                                </Link>
                            </div>
                        </div>
                        <LoginFooter text={i18n('authentication.login.se.login-form.terms', { danger: true })} />
                    </LoginResponsivePanel>
                </div>
            </div>
        );
    }
}

LoginFormNETS.propTypes = {
    resetLoginState: PropTypes.func.isRequired,
    person: PropTypes.object,
    userIsCustomer: PropTypes.bool,

    loginStatus: PropTypes.object.isRequired,

    // pending and error props used by operationSucceeded
    getSession: PropTypes.func.isRequired,
    getSessionPending: PropTypes.bool.isRequired, // eslint-disable-line react/no-unused-prop-types
    getSessionError: PropTypes.object, // eslint-disable-line react/no-unused-prop-types

    getCustomerProperty: PropTypes.func.isRequired,
    isReviewMode: PropTypes.bool,
};

LoginFormNETS.defaultProps = {
    isReviewMode: false,
    person: undefined,
    userIsCustomer: undefined,
    getSessionError: undefined,
};

export default LoginFormNETS;
