import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { i18n } from '@ecster/i18n';
import { detectDevice, GeneralErrorMessagePanel, Message, Tab, Tabs, Spinner, Link } from '@ecster/components';

import { withRouter } from 'react-router-dom';
import AuthenticatedSubPageTemplate from '../../common/templates/AuthenticatedSubPageTemplate';

import { getAccountTerms, getAccount, dismissGetAccountError, dismissGetAccountTermsError } from '../redux/actions';

import AccountTermsTab from './AccountTermsTab';
import AccountInformationTab from './AccountInformationTab';
import CloseAccountTab from './CloseAccountTab';
import { PaymentDialog } from '../PaymentDialog';
import { formatAmount } from '../../../common/util/format-amount';
import { isValidSurplusAmount } from '../../../common/util/is-valid-surplus-amount';
import { isSE } from '../../../common/country';

export class AccountTermsPage extends Component {
    state = {
        isPaymentDialogOpen: false,
    };
    // eslint-disable-next-line camelcase
    UNSAFE_componentWillMount() {
        const { getAccountTerms, getAccount, customerId, accountRef } = this.props;
        getAccount(customerId, accountRef);
        getAccountTerms(customerId, accountRef);
    }

    dismissErrors = () => {
        const { getAccountTermsError, dismissGetAccountTermsError, getAccountError, dismissGetAccountError } =
            this.props;

        if (getAccountError) dismissGetAccountError();
        if (getAccountTermsError) dismissGetAccountTermsError();
    };

    getTransactionUrl = () => {
        const { hash } = window.location;
        return hash.replace(/terms/, 'transactions');
    };

    onStartRefund = e => {
        e.preventDefault() && e.stopPropagation();
        this.setState({ isPaymentDialogOpen: true });
    };

    onPaymentDialogRequestClose = () => {
        this.setState({ isPaymentDialogOpen: false });
    };

    render() {
        const { account, accountCard, terms, getAccountTermsError, getAccountError, accountRef, getAccountPending } =
            this.props;
        const { isPaymentDialogOpen } = this.state;
        const { allowAccountClose } = account;
        const { isMobile, isDesktop, isTablet } = detectDevice();

        const accountIsTerminated = account.status === 'TERMINATED';
        const hasDebt = account.used > 0;
        const hasSurplusBalance = account.used < -100; // Överskott under 1kr räknas ej som överskott
        const message = hasDebt ? (
            i18n('account.terminated-account.debt-info', 'p', { danger: true })
        ) : hasSurplusBalance ? (
            <>
                {i18n('account.terminated-account.refundable-info', 'p', { danger: true })}
                {!isValidSurplusAmount(-account.used) &&
                    i18n('account.terminated-account.refundable-info-exceeded-limit', 'p', { danger: true })}
            </>
        ) : (
            <p>{i18n('account.terminated-account.terminated-info')}</p>
        );

        const header = hasSurplusBalance
            ? i18n('account.terminated-account.message-header-refundable')
            : i18n('account.terminated-account.message-header');

        return (
            <AuthenticatedSubPageTemplate
                className="account-terms-page"
                header={i18n('account.terms.terms-information')}
                linkTo="/account/overview"
            >
                {getAccountTermsError || getAccountError ? (
                    <GeneralErrorMessagePanel
                        header={i18n('general.error.general.header')}
                        body={i18n('general.error.general.body')}
                        error={getAccountError || getAccountTermsError}
                        dismissError={this.dismissErrors}
                    />
                ) : getAccountPending ? (
                    <Spinner id="profile-spinner" isVisible isCenterPage />
                ) : (
                    <>
                        {accountIsTerminated && (
                            <>
                                <Message className="mb-6x" blue={!hasDebt} purple={hasDebt} header={header}>
                                    <>
                                        {message}
                                        {hasSurplusBalance && isValidSurplusAmount(-account.used) && (
                                            <Link href="#" underline blue onClick={this.onStartRefund}>
                                                {i18n('account.terminated-account.refundable-link-text')}
                                            </Link>
                                        )}
                                    </>
                                </Message>
                            </>
                        )}
                        {!accountIsTerminated && hasSurplusBalance && (
                            <Message className="mb-6x" blue header={i18n('account.has-surplus.notification.header')}>
                                <p>
                                    {i18n('account.has-surplus.notification.text', {
                                        amount: formatAmount(9999, { strip00: true }),
                                    })}
                                </p>
                                {!isValidSurplusAmount(-account.used) ? (
                                    i18n('account.terminated-account.refundable-info-exceeded-limit', 'p', {
                                        danger: true,
                                    })
                                ) : (
                                    <p>
                                        <Link underline href="#" blue outline small round onClick={this.onStartRefund}>
                                            {i18n('account.has-surplus.notification.link-text')}
                                        </Link>
                                    </p>
                                )}
                            </Message>
                        )}
                        {hasSurplusBalance && isSE() && (
                            <PaymentDialog
                                account={account}
                                isOpen={isPaymentDialogOpen}
                                onRequestClose={this.onPaymentDialogRequestClose}
                            />
                        )}
                        <Tabs border={false} sideMarginsInMobile>
                            <Tab label={i18n('account.account-information.tab-header-one')}>
                                <AccountTermsTab account={account} terms={terms} />
                            </Tab>
                            <Tab label={i18n('account.account-information.tab-header-two')}>
                                <AccountInformationTab account={account} accountCard={accountCard} terms={terms} />
                            </Tab>

                            {!accountIsTerminated && (
                                <Tab label={i18n('account.account-information.tab-header-three')}>
                                    {!allowAccountClose && (
                                        <Message
                                            exclamation={isTablet || isDesktop}
                                            icon={isMobile ? false : null}
                                            header={i18n('account.account-information.terminate.disabled.header')}
                                            message={i18n('account.account-information.terminate.disabled.text', {
                                                transactionsUrl: this.getTransactionUrl(),
                                                returnObjects: true,
                                            })}
                                            className="terminate-account-disabled mb-8x"
                                            purple
                                        />
                                    )}
                                    <CloseAccountTab account={account} accountRef={accountRef} />
                                </Tab>
                            )}
                        </Tabs>
                    </>
                )}
            </AuthenticatedSubPageTemplate>
        );
    }
}

AccountTermsPage.propTypes = {
    // ajax action and its async states
    account: PropTypes.shape({
        allowAccountClose: PropTypes.bool.isRequired,
        used: PropTypes.number.isRequired,
    }).isRequired,
    accountRef: PropTypes.string.isRequired,
    customerId: PropTypes.number.isRequired,
    accountCard: PropTypes.object,
    // getAccount
    getAccount: PropTypes.func.isRequired,
    getAccountError: PropTypes.object,
    getAccountPending: PropTypes.bool.isRequired, // eslint-disable-line
    // getAccountTerms
    getAccountTerms: PropTypes.func.isRequired,
    getAccountTermsError: PropTypes.object,
    getAccountTermsPending: PropTypes.bool.isRequired, // eslint-disable-line

    terms: PropTypes.object.isRequired,

    dismissGetAccountError: PropTypes.func.isRequired,
    dismissGetAccountTermsError: PropTypes.func.isRequired,
};

AccountTermsPage.defaultProps = {
    accountCard: {},
    getAccountTermsError: null,
    getAccountError: null,
};

/* istanbul ignore next */
function mapStateToProps({ account, authentication }, { match }) {
    const { accountRef } = match.params;
    return {
        accountRef,
        account: account.account,
        customerId: authentication.person.id,
        terms: account.accountTerms,
        getAccountError: account.getAccountError,
        getAccountPending: account.getAccountPending,
        getAccountTermsError: account.getAccountTermsError,
        getAccountTermsPending: account.getAccountTermsPending,
    };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
    return {
        getAccountTerms: (customerId, accountRef) => dispatch(getAccountTerms(customerId, accountRef)),
        getAccount: (customerId, accountRef) => dispatch(getAccount(customerId, accountRef)),
        dismissGetAccountError: () => dispatch(dismissGetAccountError()),
        dismissGetAccountTermsError: () => dispatch(dismissGetAccountTermsError()),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountTermsPage));
