import React, { Component } from "react";
import PropTypes from "prop-types";

import { Logo, Spinner, TouchDevice } from "@ecster/components";
import { setBaseUrl } from "@ecster/net";
import { getCookie, removeCookie } from "@ecster/cookies";
import { i18n } from "@ecster/i18n";
import { reduxActionSucceeded } from "@ecster/util";

import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import {
  createSession,
  dismissCreateSessionError,
  getSession,
  resetLoginState,
  deleteSession,
} from "../redux/actions";
import { getCustomerProperty } from "../../customer/redux/getCustomerProperty";

import "./NetsRedirectPage.scss";
import LoginPageTemplate from "../../common/templates/LoginPageTemplate";
import { BACKEND_COOKIE } from "../../../common/backendCookie";
import { getSessionCookie } from "../../../common/sessionCookie.js";
import { reportError } from "../../../common/reportError.js";
import { NoCookieAuthPanel } from "./NoCookieAuthPanel";
import { WarningMsg } from "./WarningMsg";
import { UrlParser, get } from "@ecster/net";

/**
 * Common page for SE and FI.
 * Users will be redirected to this page after authenticating at NETS.
 */

const { EcsterConfig: conf } = window;

const pollAgainStatus = [
  "STARTED",
  "OUTSTANDING_TRANSACTION",
  "NO_CLIENT",
  "USER_SIGN",
];

const stopPollStatus = [
  "EXPIRED_TRANSACTION",
  "CERTIFICATE_ERROR",
  "USER_CANCEL",
  "CANCELLED",
  "START_FAILED",
  "TECHNICAL_ERROR",
];

const maxPolls = 10; // pollTime är 1500 ms = 1.5 minuter
let pollCount = 0;

export class NetsRedirectPage extends Component {
  static propTypes = {
    loginStatus: PropTypes.object.isRequired,
    loginProgress: PropTypes.object.isRequired,

    getSession: PropTypes.func.isRequired,
    getSessionPending: PropTypes.bool.isRequired, // eslint-disable-line react/no-unused-prop-types
    getSessionError: PropTypes.object, // eslint-disable-line react/no-unused-prop-types

    getCustomerProperty: PropTypes.func.isRequired,
    getCustomerPropertyLoginMypagesPending: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
    getCustomerPropertyLoginMypagesError: PropTypes.object,

    person: PropTypes.object,
    userIsCustomer: PropTypes.bool,
  };

  static defaultProps = {
    person: undefined,
    userIsCustomer: undefined,
    getSessionError: undefined,
    getCustomerPropertyLoginMypagesError: undefined,
    getCustomerPropertyLoginMypagesPending: false,
  };

  state = {
    sessionKey: null,
    loginFailed: false,
    loginTimeout: false,
    getPropertySucceeded: false,
    noCookie: false,
    internalToken: UrlParser.getQueryParams()["xtr"],
  };

  // eslint-disable-next-line consistent-return
  componentDidMount() {
    const { getSession } = this.props;

    const baseURL = getCookie(BACKEND_COOKIE);
    if (baseURL) {
      conf.baseURL = baseURL === "relative" ? "" : baseURL;
      setBaseUrl(conf.baseURL);
      removeCookie(BACKEND_COOKIE);
    }

    window.history.replaceState(null, "", location.origin + location.pathname + location.hash);

    if (!getSessionCookie()) {
      reportError(
        { description: "Nets redirect page: session cookie not found" },
        "nets-redirect"
      );
      this.setState({ noCookie: true });
    } else {
      getSession(++pollCount);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      getCustomerProperty,
      person,
      getSession,
      loginProgress: { status, pollTime },
    } = this.props;

    if (reduxActionSucceeded("getSession", prevProps, this.props)) {
      if (pollAgainStatus.includes(status)) {
        if (pollCount < maxPolls) {
          setTimeout(() => {
            getSession(++pollCount);
          }, pollTime);
        } else {
          this.setState({ loginTimeout: true });
        }
      } else if (stopPollStatus.includes(status)) {
        this.setState({ loginFailed: true });
      } else {
        getCustomerProperty(person.id, "LOGIN_MYPAGES");
      }
    }

    if (
      reduxActionSucceeded(
        "getCustomerPropertyLoginMypages",
        prevProps,
        this.props
      )
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ getPropertySucceeded: true });
    }
  }

  render() {
    const {
      loginStatus,
      loginProgress: { status },
      userIsCustomer,
      getSessionError,
      getCustomerPropertyLoginMypagesError,
    } = this.props;

    const { noCookie, internalToken } = this.state;

    const { getPropertySucceeded, loginFailed, loginTimeout } = this.state;

    if (status === "USER_CANCEL" || status === "CERTIFICATE_ERROR") {
      // We failed to get a session or user cancelled
      return <Redirect to="/" />;
    }

    const hasError =
      getSessionError || getCustomerPropertyLoginMypagesError || loginFailed;
    const showSpinner =
      (!status || pollAgainStatus.includes(status) || !getPropertySucceeded) &&
      !hasError &&
      !noCookie &&
      !loginTimeout;

    if (loginStatus.isLoggedIn) {
      // successful login for exiting customer
      if (userIsCustomer) {
        // private route saved (attempted by user while not logged in)?
        const nextRoute = getCookie("nextRoute");

        if (nextRoute) {
          removeCookie("nextRoute");
          return <Redirect to={nextRoute} />;
        }
        return <Redirect to="../account/overview" />;
      }

      // successful login but not a customer
      if (userIsCustomer === false) {
        return <Redirect to="../authentication/logout/false" />;
      }
    }

    return (
      <LoginPageTemplate className="tupas-redirect-page" lazy={false}>
        <TouchDevice>
          <Logo className="ecster-logo-mobile-fixed" withName width="232px" />
        </TouchDevice>

        {showSpinner && (
          <Spinner id="login-fi-spinner" isVisible isCenterX isCenter />
        )}

        {noCookie && (
          <>
            <NoCookieAuthPanel internalToken={internalToken} />
          </>
        )}
        {hasError && (
          <WarningMsg
            header={i18n("authentication.nets.failed.header")}
            text={<p>{i18n("authentication.nets.failed.text")}</p>}
            linkText={i18n("authentication.nets.failed.link")}
            linkTarget="/"
          />
        )}
        {loginTimeout && (
          <WarningMsg
            header={i18n("authentication.nets.timeout.header")}
            text={<p>{i18n("authentication.nets.timeout.text")}</p>}
            linkText={i18n("authentication.nets.timeout.link")}
            linkTarget="/"
          />
        )}
      </LoginPageTemplate>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps({ authentication, customer }) {
  return {
    loginProgress: authentication.loginProgress,
    loginStatus: authentication.loginStatus,
    person: authentication.person,

    userIsCustomer: customer.LOGIN_MYPAGES,

    createSessionPending: authentication.createSessionPending,
    createSessionError: authentication.createSessionError,

    getSessionPending: authentication.getSessionPending,
    getSessionError: authentication.getSessionError,

    getCustomerPropertyLoginMypagesError:
      customer.getCustomerPropertyLoginMypagesError,
    getCustomerPropertyLoginMypagesPending:
      customer.getCustomerPropertyLoginMypagesPending,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    createSession: (data) => {
      dispatch(createSession(data));
    },
    deleteSession: (key) => {
      dispatch(deleteSession(key));
    },
    dismissCreateSessionError: () => {
      dispatch(dismissCreateSessionError());
    },
    resetLoginState: () => {
      dispatch(resetLoginState());
    },
    getSession: (count) => {
      dispatch(getSession(null, count));
    },
    getCustomerProperty: (customerId, propertyName) => {
      dispatch(getCustomerProperty(customerId, propertyName));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NetsRedirectPage);
