import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { i18n } from '@ecster/i18n';
import {
    Button,
    ButtonGroup,
    ExpandableContainer,
    Panel,
    PanelContent,
    Spinner,
    TextArea,
    UserMessagePanel,
} from '@ecster/components';
import { disappointedFaceIcon, happyFaceIcon } from '@ecster/icons/H80/green';
import PendingCardInfoMessage from '../PendingCardInfoMessage';
import ActivateCardInfoMessage from './ActivateCardInfoMessage';
import * as actions from '../../account/redux/actions';
import AuthenticatedSubPageTemplate from '../../common/templates/AuthenticatedSubPageTemplate';
import FaqPanel from '../FaqPanel';
import BlockCardPanel from '../BlockCardPanel';
import ShowCardPanel from '../ShowCardPanel';

const Wrapper = ({ children, pageHeader }) => (
    <AuthenticatedSubPageTemplate className="card-manage-card-page-fi" header={pageHeader}>
        {children}
    </AuthenticatedSubPageTemplate>
);

Wrapper.propTypes = {
    children: PropTypes.object.isRequired,
    pageHeader: PropTypes.string.isRequired,
};

export const ManageCardPageFi = () => {
    const dispatch = useDispatch();

    // component state
    const [message, setMessage] = useState(''); // user's additional message when applying
    // url params
    const { accountRef } = useParams();

    // authentication redux state
    const {
        person: { id: customerId },
    } = useSelector(state => state.authentication, shallowEqual);

    // account redux state
    const {
        account,
        accountCards,
        createAccountCardIsDone,
        createAccountCardIsError,
        createAccountCardPending,
        getAccountCardsIsDone,
        getAccountIsDone,
    } = useSelector(state => state.account, shallowequal);

    const accountCard = accountCards && accountCards[accountRef];

    // didMount
    useEffect(() => {
        dispatch(actions.getAccount(customerId, accountRef));
        dispatch(actions.getAccountCards(customerId, accountRef));
    }, [accountRef, customerId, dispatch]);

    // willUnmount
    useEffect(
        () => () => {
            dispatch(actions.dismissCreateAccountCardError());
            dispatch(actions.clearCreateAccountCardReduxState());
            dispatch(actions.clearGetAccountCardsReduxState());
            dispatch(actions.clearGetAccountCardsReduxState());
        },
        [dispatch]
    );

    const onChangeMessage = ({ target }) => {
        setMessage(target.value);
    };

    const onApplyForCard = () => {
        dispatch(actions.createAccountCard(customerId, accountRef, message));
    };

    const pageHeader = i18n('card.manage-card.page-header');

    if (!getAccountIsDone && !getAccountCardsIsDone) {
        return (
            <Wrapper pageHeader={pageHeader}>
                <Spinner id="manage-card-page-fi-spinner" isVisible isCenterPage />
            </Wrapper>
        );
    }

    if (createAccountCardIsError) {
        return (
            <Wrapper pageHeader={pageHeader}>
                <UserMessagePanel
                    icon={disappointedFaceIcon}
                    header={i18n('card.apply-for-card.failure.header')}
                    text={i18n('card.apply-for-card.failure.info')}
                    isGaEnabled
                    gaCategory="card"
                    gaAction="apply"
                />
            </Wrapper>
        );
    }

    if (createAccountCardIsDone) {
        return (
            <Wrapper pageHeader={pageHeader}>
                <UserMessagePanel
                    icon={happyFaceIcon}
                    header={i18n('card.apply-for-card.success.header')}
                    body={<>{i18n('card.apply-for-card.success.info', 'p')}</>}
                    isGaEnabled
                    gaCategory="card"
                    gaAction="apply"
                />
            </Wrapper>
        );
    }

    const showCardInfo = ['ACTIVE', 'INACTIVE', 'BLOCKED'].includes(accountCard?.status);
    const cardIsOrdered = ['ORDERED'].includes(accountCard?.status);
    const cardIsInactive = ['INACTIVE'].includes(accountCard?.status);

    return (
        <Wrapper pageHeader={pageHeader}>
            {showCardInfo && <ShowCardPanel account={account} accountCard={accountCard} />}
            {cardIsOrdered && <PendingCardInfoMessage />}
            {cardIsInactive && <ActivateCardInfoMessage />}
            {!cardIsOrdered && !cardIsInactive && (
                <Panel sideMarginsInMobil id="apply-for-new-card">
                    <PanelContent centered>
                        <h2>{i18n('card.apply-for-card.header')}</h2>
                        <p>{i18n('card.apply-for-card.info')}</p>
                    </PanelContent>
                    <PanelContent xNarrow>
                        <ExpandableContainer
                            id="apply-for-card-and-pin-msg"
                            header={<span>{i18n('card.apply-for-card.message.label')}</span>}
                        >
                            <TextArea
                                placeholder={i18n('card.apply-for-card.message.placeholder')}
                                onChange={onChangeMessage}
                                value={message}
                                maxLength={1000}
                            />
                        </ExpandableContainer>
                        <ButtonGroup alignCenter>
                            <Button
                                id="apply-for-card-button"
                                round
                                onClick={onApplyForCard}
                                ignoreClick={createAccountCardPending}
                                spinnerRight={createAccountCardPending}
                            >
                                {i18n('card.apply-for-card.button')}
                            </Button>
                        </ButtonGroup>
                    </PanelContent>
                </Panel>
            )}
            <FaqPanel />
            <BlockCardPanel />
        </Wrapper>
    );
};

ManageCardPageFi.propTypes = {};

ManageCardPageFi.defaultProps = {};

export default ManageCardPageFi;
