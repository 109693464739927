import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, Link, Logo, Option, Select, GeneralErrorMessagePanel } from '@ecster/components';
import { i18n } from '@ecster/i18n';
import { Cookie } from '@ecster/net';
import { LocaleContext } from '../../common/LocaleContext';
import LoginFooter from '../../home/LoginFooter';
import { LoginResponsivePanel } from '../components/LoginResponsivePanel';
import { AppWillCloseMessage } from '../components/AppWillCloseMessage';
import { AppIsClosedMessage } from '../components/AppIsClosedMessage';
import GlobalNotificationsPanel from '../../../common/GlobalNotificationsPanel';

const BANK_COOKIE = 'ecster-selected-tupas-bank';
const SESSION_COOKIE = 'ecster-sc';

const BANKS = [
    { label: 'Aktia', value: 'aktia' },
    { label: 'Ålandsbanken', value: 'aland' },
    { label: 'Danske Bank', value: 'danske' },
    { label: 'Handelsbanken', value: 'handelsbanken' },
    { label: 'Nordea', value: 'nordea' },
    { label: 'Oma Säästöpankki', value: 'omasp' },
    { label: 'Osuuspankki', value: 'opbank' },
    { label: 'POP Pankki', value: 'popbank' },
    { label: 'Säästöpankki', value: 'savingsbank' },
    { label: 'S-Pankki', value: 'sbank' },
];

class LoginFormFI extends Component {
    state = {
        selectedBank: Cookie.read(BANK_COOKIE),
        userMessage: '',
        hasTupasProviderError: false,
    };

    componentDidMount() {
        const {
            loginProgress: { status },
        } = this.props;

        /* Status will be empty on start - otherwise we're returning from a failed tupas login */
        if (status === 'USER_CANCEL') {
            this.setState({
                userMessage: i18n('authentication.login.fi.messages.login-cancelled'),
            });
        } else if (typeof status !== 'undefined') {
            // 'CERTIFICATE_ERROR',
            // 'EXPIRED_TRANSACTION',
            // 'CERTIFICATE_ERROR',
            // 'CANCELLED',
            // 'START_FAILED',
            // 'TECHNICAL_ERROR',
            this.setState({
                userMessage: i18n('authentication.login.fi.messages.login-failed'),
            });
        }
    }

    componentDidUpdate(prevProps) {
        const {
            loginStatus: { sessionKey: oldSessionkey },
        } = prevProps;

        const {
            loginStatus: { sessionKey },
        } = this.props;

        if (oldSessionkey !== sessionKey && sessionKey) {
            const { loginProgress } = this.props;
            const { selectedBank } = this.state;
            const startURL =
                loginProgress && loginProgress.startURL && `${loginProgress.startURL}&forcebank=${selectedBank}`;

            Cookie.create(SESSION_COOKIE, sessionKey);
            window.location.href = startURL;
        }
    }

    onSelectBank = ({ target: { value } }) => {
        this.setState({ selectedBank: value, userMessage: null });
        Cookie.create(BANK_COOKIE, value);
    };

    onClickLogin = () => {
        const { selectedBank } = this.state;

        if (selectedBank) {
            Cookie.create(BANK_COOKIE, selectedBank);
            this.startTupasLogin();
        }
    };

    startTupasLogin = () => {
        const { createSession } = this.props;
        const { language, env } = window.EcsterConfig;
        const isDev = env === 'dev';

        const url = new URL(window.location.href);
        url.hash = '#/tupas/login';

        // To develop with NETS: set EcsterConfig.devCanaryValue = 1 in console, Use secureft4 and credentials here: https://www.nets.eu/developer/e-ident/eids/Pages/testusers.aspx;
        createSession({
            type: 'TUPAS',
            country: 'FI',
            locale: { country: 'FI', language },
            returnUrl: url.href,
        });
    };

    persistLangChange = async changeLang => {
        Cookie.create('isPersistLocale', 1);
        await changeLang;
    };

    render() {
        const { selectedBank, userMessage, hasTupasProviderError } = this.state;

        if (hasTupasProviderError) {
            return (
                <div className="login-responsive-panel-ctr">
                    <LoginResponsivePanel id="login-page-panel">
                        <GeneralErrorMessagePanel
                            header={i18n('general.error.general.header')}
                            body={i18n('general.error.general.body')}
                            onButtonClick={() => this.setState({ hasTupasProviderError: false })}
                            buttonText={i18n('general.back')}
                            isLink
                        />
                    </LoginResponsivePanel>
                </div>
            );
        }

        return (
            <>
                <AppIsClosedMessage />
                <div className="login-responsive-panel-ctr">
                    <AppWillCloseMessage />
                    <GlobalNotificationsPanel onlyForcePopup />
                    <LoginResponsivePanel id="login-page-panel">
                        <div id="login-form-fi" className="login-form-fi login-form">
                            <LocaleContext.Consumer>
                                {({ locale, changeLang }) => {
                                    return (
                                        <div className="login-form-tupas">
                                            <div className="login-form-header">
                                                <div className="ecster-logo-container">
                                                    <Logo className="ecster-logo" withName width="190px" />
                                                </div>
                                                <h1 className="e-yellow">
                                                    {i18n('authentication.login.fi.desktop.header')}
                                                </h1>
                                                <p className="e-white">
                                                    {i18n('authentication.login.fi.desktop.body')}
                                                </p>
                                            </div>
                                            <div className="change-bank">
                                                <Select
                                                    ariaLabel={i18n('authentication.login.fi.select-placeholder')}
                                                    className="select-bank"
                                                    id="select-tupas-bank"
                                                    value={selectedBank || ''}
                                                    noDefault={!!selectedBank}
                                                    defaultOption={i18n('authentication.login.fi.select-placeholder')}
                                                    onChange={this.onSelectBank}
                                                >
                                                    {BANKS.map(bank => (
                                                        <Option
                                                            key={bank.label}
                                                            label={bank.label}
                                                            value={bank.value}
                                                        />
                                                    ))}
                                                </Select>
                                                <ButtonGroup alignCenter>
                                                    <Button
                                                        type="button"
                                                        formNoValidate
                                                        onClick={this.onClickLogin}
                                                        round
                                                        name="login-button"
                                                    >
                                                        {i18n('authentication.login.fi.desktop.login-button')}
                                                    </Button>
                                                </ButtonGroup>
                                            </div>
                                            {userMessage && <p className="user-message">{userMessage}</p>}
                                            <div className="change-lang-footer">
                                                <div className="change-lang">
                                                    <Select
                                                        ariaLabel={i18n('general.select-lang.dropdown')}
                                                        id="change-lang"
                                                        noDefault
                                                        border={false}
                                                        value={locale || ''}
                                                        onChange={e =>
                                                            this.persistLangChange(changeLang(e.target.value))
                                                        }
                                                    >
                                                        <Option
                                                            value="sv-FI"
                                                            label={i18n('general.select-lang.swedish')}
                                                        />
                                                        <Option
                                                            value="fi-FI"
                                                            label={i18n('general.select-lang.finnish')}
                                                        />
                                                    </Select>
                                                </div>
                                                <div className="spacer">&nbsp;</div>
                                                <div className="help">
                                                    <Link white id="login-help-button" strong to="/authentication/help">
                                                        {i18n('general.help')}
                                                    </Link>
                                                </div>
                                            </div>
                                            <LoginFooter
                                                text={i18n('authentication.login.fi.terms', { danger: true })}
                                            />
                                        </div>
                                    );
                                }}
                            </LocaleContext.Consumer>
                        </div>
                    </LoginResponsivePanel>
                </div>
            </>
        );
    }
}

LoginFormFI.propTypes = {
    loginProgress: PropTypes.object.isRequired,
    loginStatus: PropTypes.object.isRequired,

    // pending and error props used by operationSucceeded
    createSession: PropTypes.func.isRequired,
    createSessionPending: PropTypes.bool.isRequired, // eslint-disable-line react/no-unused-prop-types
    createSessionError: PropTypes.object, // eslint-disable-line react/no-unused-prop-types

    getSessionPending: PropTypes.bool.isRequired, // eslint-disable-line react/no-unused-prop-types
    getSessionError: PropTypes.object, // eslint-disable-line react/no-unused-prop-types
};

LoginFormFI.defaultProps = {
    createSessionError: undefined,
    getSessionError: undefined,
};

export default LoginFormFI;
